@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap');

$menu-font-size: 14px;
$default-font-size: 16px;
$mobile-font-size: 14px;
$site-width: 900px;
$menu-max-width: 950px;
$tablet-max-width: 850px;
$desktop-min-width: 951px;
$mobile-max-width: 450px;

html {
    scroll-behavior: smooth;
}

body {
    background-image: url(./assets/beam2.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 3000px auto;
    font-size: $default-font-size;

    &.merch {
        background-image: none;
    }

    @media (max-width: $menu-max-width) {
        background-size: 2000px auto;
    }

    @media (max-width: 700px) {
        font-size: $mobile-font-size;
        background-size: 1800px auto;
    }

    @media (max-width: 600px) {
        font-size: $mobile-font-size;
        background-size: 280% auto;
    }

    * {
        font-family: 'Montserrat', sans-serif;
    }
}

.free-shipping {
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    margin: 0 auto 30px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    cursor: pointer;

    @media (max-width: 600px) {
        font-size: 14px;
    }

    @media (max-width: 450px) {
        margin-bottom: 10px;
    }
}

.free-shipping-cart {
    background: #2a82b9;
    color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-top: -2px;
    display: inline-block;
}

.clearboth {
    clear: both;
}

.App {
    @media (min-width: $tablet-max-width) {
        margin-top: 200px;
    }

    &.inner-page {
        margin-top: 150px;

        @media (max-width: $menu-max-width) {
            margin-top: 100px;
        }
    }
}

p {
    line-height: 1.5;

    @media (max-width: $menu-max-width) {
        margin-top: 0;
    }
}

h2, h3, h4, h5, h6 {
    color: #001320;
}

h2 {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 30px;
    font-weight: 700;

    @media (max-width: $menu-max-width) {
        font-size: 30px;
    }

    @media (max-width: 350px) {
        font-size: 20px;
    }
}

.centered {
    text-align: center;
}

button {
    cursor: pointer;
}

.section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    @media (max-width: $menu-max-width) {
        padding: 0 50px;
    }

    @media (max-width: 550px) {
        padding: 0 30px;
    }

    .section-body {
        max-width: $site-width;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.modal {
    margin-top: 75px;
}

#netlify-identity-widget {
    @media (max-width: 479px) {
        margin-top: 75px;
    }
}

.modal-700px {
    max-width: 700px;
}

.modal-900px {
    max-width: 900px;
}

.App {
    .btn-primary {
        background: #c76828;
        color: #fff;
        padding: 10px 40px;
        border: none;
        font-size: $default-font-size;
        font-weight: 600;
        display: block;
        padding: 15px 50px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 350px) {
            font-size: 14px;
            padding: 10px 20px;
            width: 100%;
        }
    }

    .btn-secondary.cancel {
        background-color: #FFF;
        color: #004F6A;
        border: 1px solid #3cb1d8;
        float: right;
    }
}

.btn-secondary, .more-cancel {
    background: #004F6A;
    color: #fff;
    padding: 10px 40px;
    border: 2px solid #fff;
    font-size: $default-font-size;
    font-weight: 600;
    display: block;
    padding: 15px 50px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;

    &:focus, &:hover {
        background: #004F6A;
    }

    @media (max-width: 350px) {
        font-size: 14px;
        padding: 10px 20px;
        width: 100%;
    }
}

.more-cancel {
    float: right;
    text-decoration: none;
    padding: 10px 30px;

    &:hover {
        color: #FFF;
    }
}

.btn-link {
    border: 1px solid #004F6A;
    color: #004F6A;
    background: transparent;
    border-radius: 5px;
    font-weight: 500;
    padding: 2px 7px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
}

div.App {
    &:not(#App) #menu-system {
        #menu-inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: auto;

            @media (min-width: $desktop-min-width) {
                max-width: 900px;
            }

            @media (max-width: $menu-max-width) {
                display: block;
            }
        }

        #h-logo {
            cursor: pointer;
        }

        #mobile-menu-interface {
            @media (min-width: $desktop-min-width) {
                width: 300px;
                position: static;
                display: block;
                padding: 10px 20px 10px 0;
                
                #h-logo {
                    max-width: 100%;
                }

                #expand-menu {
                    display: none;
                }
            }
        }

        #h-logo {
            opacity: 1;
        }
    }

    &#Merch {
        #menu-system {
            #h-logo {
                opacity: 0;
            }

            &.sticky {
                #h-logo {
                    opacity: 1;
                }
            }
        }

        a:hover, .sprd-startpage-preview-tiles__text {
            color: #000 !important;
        }
    }
}

#menu-system:not(.active) {
    @media (max-width: $menu-max-width) {
        overflow: hidden;
    }
}

#menu-system {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 50;
    margin: 30px 0;
    height: 75px;

    &.sticky {
        position: fixed;
        z-index: 9999;
        margin: 0;
        background-color: #fff;
        -webkit-transition: background-color 0.5s;

        box-shadow: 0 0 8px 0px rgba(0,0,0,0.25);
        -webkit-box-shadow: 0 0 8px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 0 0 8px 0px rgba(0,0,0,0.25);

        #h-logo {
            opacity: 1;
        }

        #mobile-menu-interface {
            position: fixed;
        }
    }

    @media (max-width: $menu-max-width) {
        &.active {
            #h-logo {
                opacity: 1;
            }
        }

        &:not(.active) {
            #menu {
                opacity: 0;
                margin-top: -100%;
            }
        }
    }

    @media (max-width: 700px) {
        position: fixed;
        margin-top: 0;
    }

    #mobile-menu-interface {
        padding: 20px 50px;
        box-sizing: border-box;
        position: absolute;
        z-index: 50;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        @media (min-width: $desktop-min-width) {
            display: none;
        }

        @media (max-width: 550px) {
            padding: 20px 30px;
        }
    }

    #expand-menu {
        fill: #001320;
        cursor: pointer;
    }

    #h-logo {
        max-height: 30px;
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        max-width: 50%;

        @media (max-width: 500px) {
            max-width: 60%;
        }

        @media (max-width: $mobile-max-width) {
            max-width: 70%;
        }
    }

    #menu {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        padding-inline-start: 0;
        margin: 0;
        -webkit-transition: opacity 0.5s, margin-top 0.5s;

        @media (max-width: $menu-max-width) {
            background-color: #fff;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 100px;
            margin: -30px 0 0;

            .btn-primary {
                margin: 0;
            }
        }

        li {
            display: block;
            position: relative;

            @media (max-width: $menu-max-width) {
                margin-left: 0;
                margin-right: 0;
                text-align: center;
            }

            @media (max-width: 350px) {
                width: 80%;
            }
            
            a {
                display: block;
                padding: 10px 23px;
                margin-left: 2px;
                margin-right: 2px;
                text-decoration: none;
                color: #000;
                font-size: $menu-font-size;
                font-weight: 500;
                border-radius: 5px;

                @media (max-width: 950px) {
                    margin-bottom: 5px;
                }

                &.active, &:hover {
                    background-color: #e7f4f7;
                }

                &.about-link {
                    @media (max-width: $menu-max-width) {
                        display: none;
                    }
                }
            }

            .btn-primary {
                margin-right: 0;
            }

            .btn-primary {
                margin: 0 25px 0 15px;
                padding: 10px 40px;
                border-radius: 7px;
                font-size: $menu-font-size;

                @media (max-width: $menu-max-width) {
                    margin-top: 15px;
                    margin-bottom: 20px;
                }

                @media (max-width: 350px) {
                    font-size: 14px;
                    padding: 10px 20px;
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            &.more {
                display: flex;
                align-items: center;
                margin-right: -5px;
                padding-left: 20px;
                padding-right: 20px;

                img {
                    max-height: 20px;
                    cursor: pointer;

                    @media (max-width: $menu-max-width) {
                        display: none;
                    }
                }
            }

            ul {
                position: absolute;
                left: -10px;
                top: 30px;
                z-index: 5;
                padding-left: 0;
                background: #fff;
                box-shadow: 0 5px 4px 0px rgba(0,0,0,0.16);
                -webkit-box-shadow: 0 5px 4px 0px rgba(0,0,0,0.16);
                -moz-box-shadow: 0 5px 4px 0px rgba(0,0,0,0.16);
                display: none;
                width: 150px;

                @media (max-width: $menu-max-width) {
                    display: block;
                    position: static;
                    box-shadow: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                }
            }

            &:hover {
                ul {
                    display: block;
                }
            }
        }
    }
}

.anchor {
    position: relative;
    top: -140px;

    &#preview {
        top: -340px;
    }

    @media (max-width: $mobile-max-width) {
        top: -120px;

        &#preview {
            top: -320px;
        }
    }
}

#netlify-modal {
    height: 100vh;
    width: 100%;
    z-index: 9999;
    position: absolute;
}

header.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 750px;
    position: relative;

    @media (max-width: $tablet-max-width) {
        margin-top: 150px;
    }

    @media (max-width: $mobile-max-width) {
        margin-top: 80px;
    }

    > * {
        margin-left: auto;
        margin-right: auto;
    }

    #top-logo {
        max-width: 650px;
        margin-bottom: 40px;
    }

    #conflict {
        max-width: 650px;
        width: 100%;
        margin-bottom: 40px;
    }

    p {
        text-align: justify;
        margin-bottom: 70px;
    }

    button, p {
        position: relative;
        z-index: 1;

        margin-bottom: 50px;

        @media (max-width: $menu-max-width) {
            padding: 8px 25px;
            border-radius: 7px;
            font-size: 14px;
        }
    }

    #astronaut {
        max-width: 400px;
        width: 100%;
        margin: 100px auto;
        position: relative;
        z-index: 1;

        @media (max-width: $tablet-max-width) {
            width: 90%;
            margin-top: 50px;
        }
    }

    .bottom-curtain {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 200px;
        background-color: #9cd7e1;
        z-index: 0;
        display: none;

        @media (max-width: 500px) {
            display: block;
        }

        @media (max-width: 300px) {
            height: 500px;
        }
    }
}

#Breadcrumb {
    max-width: $site-width;
    margin: auto;

    ul {
        display: flex;
        list-style: none;
        margin: 0 20px;
        padding: 0;

        @media (max-width: $menu-max-width) {
            margin: 0 50px;
        }

        li {
            margin-right: 8px;

            a {
                text-decoration: none;
                color: #000;

                &:hover {
                    text-decoration: underline;
                }

                .arrow {
                    font-size: 12px;
                    position: relative;
                    top: -1px;
                    margin-left: 5px;
                }
            }
        }
    }
}

.curtain {
    background-color: #fff;
}

.issue-1 {
    max-width: 450px;
    position: absolute;
    right: -140px;
    top: -15px;
}

.product-display.section {
    position: relative;
    overflow: hidden;
    padding-top: 15px;
    margin-bottom: 130px;

    @media (max-width: 430px) {
        margin-top: -80px;
    }

    .section-body {
        position: relative;
        z-index: 10;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 350px;
        box-sizing: border-box;

        @media (max-width: $menu-max-width) {
            padding-right: 0;
            align-items: center;

            h2 {
                width: 100%;
            }

            .issue-1 {
                position: static;
                margin-left: 140px;
                margin-bottom: 50px;

                @media (max-width: 700px) {
                    margin-left: 30%;
                }

                @media (max-width: 550px) {
                    width: 80%;
                    margin-left: 25%;
                }
            }

            .btn-primary {
                margin-top: 30px;
            }
        }
    }

    .btn-primary {
        margin-top: 40px;
    }

    .curtain {
        margin-top: 75px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 0;
    }
}

#the-dream-wrap {
    margin-bottom: 0;
    padding-bottom: 480px;
}

#the-author-wrap.section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 200px;
    margin-top: -350px;
    max-width: $site-width;
    position: relative;
    z-index: 5;

    @media (max-width: $menu-max-width) {
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    
    .anchor {
        position: absolute;
        top: -140px;
    }

    .section-body {
        display: block;
    }

    #first-contact {
        width: 390px;
        height: auto;
        margin-right: 25px;
        margin-bottom: 40px;

        @media (max-width: 350px) {
            width: 300px;
        }
    }

    .btn-link {
        float: right;

        @media (max-width: 350px) {
            float: none;
            margin: auto;
            display: block;
        }
    }
}

#credits {
    background-color: #020a10;
    background-image: url(./assets/cavern.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 1500px auto;
    margin-top: -350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    #cavern-logo {
        width: 800px;
        margin-top: 400px;
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 5;
        max-width: 100%;
    }

    #spelunker {
        position: absolute;
        left: 50%;
        top: 230px;
        margin-left: -750px;
        width: 800px;

        @media (max-width: 1000px) {
            display: none;
        }
    }

    @media (max-width: $tablet-max-width) {
        margin-top: -400px;
        background-size: 1200px auto;
    }

    .section-body {
        min-height: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 700px;
        width: 100%;
        position: relative;
        z-index: 5;

        h2, h5 {
            text-transform: uppercase;
            font-size: 36px;
            font-weight: 300;
            color: #fff;
            text-align: center;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        h3 {
            font-size: 60px;
            font-weight: 600;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            margin-top: -5px;
            margin-bottom: 80px;
        }

        h4 {
            font-size: 40px;
            font-weight: 600;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            margin: 20px auto 0;
            max-width: 500px;
            line-height: 1;
        }

        h5 {
            margin-top: 25px;
            font-size: 30px;
        }

        @media (max-width: $tablet-max-width) {
            h2 {
                font-size: 24px;
            }

            h3 {
                font-size: 40px;
                margin-bottom: 60px;
            }

            h4 {
                font-size: 30px;
            }

            h5 {
                font-size: 20px;
            }
        }

        @media (max-width: $mobile-max-width) {
            h2 {
                font-size: 20px;
            }

            h3 {
                font-size: 30px;
                margin-bottom: 60px;
            }

            h4 {
                font-size: 20px;
            }

            h5 {
                font-size: 14px;
            }
        }

        @media (max-width: 300px) {
            h2 {
                font-size: 14px;
                margin-bottom: 5px;
            }

            h3 {
                font-size: 20px;
                margin-bottom: 30px;
            }

            h4 {
                font-size: 20px;
            }

            h5 {
                font-size: 14px;
            }

            p {
                font-size: 12px;
            }
        }

        #gcs {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 50px;
        }

        p.copyright {
            text-align: center;
            font-weight: 500;
            color: #64B3FF;
            text-transform: uppercase;
            line-height: 1.1;
            margin-bottom: 100px;
        }

        #preview-link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 400px;

            @media (max-width: 500px) {
                margin-bottom: 300px;
            }

            #book {
                width: 80px;
                margin: 0 auto 25px;

                @media (max-width: 350px) {
                    width: 60px;
                }
            }
        }
    }
}

#available-now {
    padding-top: 0;
    min-height: 520px;
    margin-top: -105px;
    margin-bottom: 30px;

    @media (max-width: 950px) {
        margin-top: -230px;
        margin-bottom: 70px;
    }

    @media (max-width: 850px) {
        margin-top: -230px;
    }

    // @media (min-width: 951px) {
    //     margin-top: -105px;
    //     margin-bottom: 30px;
    // }

    .section-body {
        img {
            top: 0;
        }
    }

    .section-text {
        min-height: 520px;
        margin-top: 15px;

        @media (max-width: 950px) {
            min-height: 0;
        }
    }

    .curtain {
        margin-top: 80px;

        @media (max-width: 950px) {
            margin-top: 230px;
        }

        @media (max-width: 850px) {
            margin-top: 80px;
        }
    }

    @media (min-width: $desktop-min-width) {
        h2 {
            color: #fff;
        }
    }
}

#join-the-crew-wrap {
    background-color: #000;
    background-image: url(./assets/nebula.jpg), url(./assets/splatter.png);
    background-position: right -300px bottom, left -170px bottom;
    background-repeat: no-repeat;
    background-size: contain, auto 150px;
    color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
    margin-bottom: 100px;
    overflow: visible;

    #join-the-crew {
        @media (max-width: $menu-max-width) {
            top: -500px;
        }

        @media (max-width: 700px) {
            top: -400px;
        }

        @media (max-width: 500px) {
            top: -350px;
        }
    }

    h2 {
        color: #fff;
    }

    #laser {
        height: 350px;
        width: auto;
        position: absolute;
        right: 56%;
        bottom: -13px;
    }

    .section-body {
        padding-left: 400px;
        box-sizing: border-box;

        .btn-secondary {
            background: #000;
            color: #FFE900;
            border-color: #FFE900;
            margin-top: 40px;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        
        button {
            margin: 0 20px;
        }
    }

    @media (max-width: $menu-max-width) {
        padding-top: 430px;

        #laser {
            right: 200px;
            top: 30px;
        }

        .section-body {
            padding-left: 0;
            max-width: 500px;
        }
    }

    @media (max-width: 700px) {
        padding-top: 320px;

        #laser {
            height: 250px;
            right: 100px;
        }
    }

    @media (max-width: 500px) {
        padding-top: 280px;

        #laser {
            height: 200px;
            right: 30px;
        }

        button {
            padding: 15px 20px;
        }
    }

    @media (max-width: 400px) {
        .buttons {
            button {
                margin: 0 5px;
            }
        }
    }
}

.newsletter-section {
    overflow: visible;
    margin-bottom: 100px;

    @media (max-width: $menu-max-width) { 
        padding: 0;
    }

    .newsletter-strip {
        background-image: url(./assets/Background-01-sm.png);
        background-position: -500px top;
        background-repeat: repeat-x;
        background-size: auto 100%;
        
        @media (max-width: 700px) {
            background-position: -1200px top;
        }

        .section-body {
            color: #ffffff;
            position: relative;
            max-width: 1000px;

            #astronaut-2 {
                position: absolute;
                width: 350px;
                right: -100px;

                @media (max-width: 1100px) {
                    right: 10px;
                    top: -25px;
                    width: 250px;
                }

                @media (max-width: 700px) {
                    right: auto;
                    top: -40px;
                    left: calc(50% - 125px);
                }
            }

            #newsletter-signup {
                width: calc(100% - 230px);
                padding: 40px 50px;
                background: url(./assets/black-faded-bg.png) center center no-repeat;
                background-size: 100% 100%;

                @media (max-width: 700px) {
                    width: 100%;
                    padding-top: 260px;
                    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 35%, rgba(0,0,0,0) 100%);
                }

                @media (max-width: 550px) {
                    padding: 260px 30px 50px;
                }

                h2 {
                    color: #ffffff;
                    padding-bottom: 0;
                    margin-bottom: 15px;
                }

                p {
                    margin-top: 0;
                    margin-bottom: 25px;
                    color: #ffffff;
                }

                .formRow {
                    justify-content: flex-start;
                    align-items: stretch;

                    @media (max-width: 700px) {
                        justify-content: space-between;
                    }
                }

                #newsletter-signup-form.hide {
                    display: none;
                }

                input, button {
                    border: 0;
                    width: auto;
                    margin: 0;
                    outline: none;
                    box-sizing: border-box;
                    display: inline-block;
                    font-size: 16px;
                    line-height: 1;
                    height: 50px;
                }

                input {
                    width: 60%;
                    padding: 0 20px !important;
                    margin-right: 10px !important;

                    @media (max-width: 700px) {
                        width: 100%;
                    }

                    @media (max-width: 600px) {
                        margin-right: 0 !important;
                        margin-bottom: 10px;
                    }
                }

                button {
                    padding: 0 30px !important;
                    border: 0;
                }

                #newsletter-warning, #newsletter-success {
                    display: none;
                    font-weight: bold;

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
}


#newsletter-confirming.hide {
    display: none;
}

#newsletter-confirmed, #newsletter-problem, #newsletter-unsubscribed {
    display: none;

    &.active {
        display: block;
    }
}

.App form {
    h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    p {
        color: #777;
        font-size: 14px;
    }

    .formRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 15px;

        @media (max-width: 600px) {
            flex-direction: column;
            margin-bottom: 0;
        }

        input, textarea {
            // color: #808080;
            font-weight: 500;
            padding: 20px;
            border-radius: 10px;
            border: 2px solid #112233;
            margin-right: 15px;

            &:last-of-type {
                margin-right: 0;
            }

            @media (max-width: 600px) {
                margin-bottom: 15px;
                margin-right: 0 !important;
                width: auto !important;
                padding: 15px;
            }
        }

        select {
            border: 1px solid #112233;
            border-radius: 10px;

            @media (max-width: 600px) {
                margin-bottom: 15px;
                margin-right: 0 !important;
                width: auto !important;
                padding: 10px 20px;
            }
        }

        input, textarea, select {
            &.problem {
                border-color: #f24c34;
                border-width: 2px;
            }
        }

        .label {
            max-width: 400px;
            p {
                margin-bottom: 0;
            }
        }

        .form-response {
            display: none;
            height: 54px;
            align-items: center;
            margin-right: 20px;
            max-width: 550px;
    
            &.active {
                display: flex;
            }
        }
    
        &.submitRow {
            justify-content: flex-end;

            button {
                margin: 0;
                padding: 10px 30px;
            }
        }
    }

    .formSection {
        padding: 20px;
        box-shadow: 0 0 8px 0px rgba(0,0,0,0.25);
        -webkit-box-shadow: 0 0 8px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 0 0 8px 0px rgba(0,0,0,0.25);
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 15px;
    }

    #subscribe-to-newsletter-row {
        margin-bottom: 0;
    }

    .form-check-input {
        width: 50px;
        height: 25px;
        border-radius: 20px;
    }

    .form-warning {
        color:#f24c34;
    }

    .form-success {
        color: #17b50d;
    }

    &#profile {
        input, textarea {
            padding: 10px 20px;
            border: 1px solid #112233;

            &.problem {
                border-color: #f24c34;
                border-width: 2px;
            }
        }

        select {
            margin-right: 15px;

            @media (max-width: 600px) {
                margin-right: 0 !important;
            }
        }

        button.save {
            padding: 10px 40px;
        }

        .header {
            @media (max-width: 600px) {
                display: block;
                text-align: center;

                h2 {
                    margin-bottom: 10px;
                }
            }

            button {
                margin: 0;

                @media (max-width: 600px) {
                    display: inline;
                }
            }
        }
    }
}

.inner-page .section-body {
    @media (max-width: 600px) {
        margin: 0;
    }
}

#get-in-touch-wrap {
    margin-bottom: 50px;

    @media (max-width: 600px) {
        .section-body {
            width: 100%;
        }
    }

    h2 {
        text-align: center;
    }

    img {
        max-width: 600px;
        margin: 0 auto 15px;

        @media (max-width: $tablet-max-width) {
            max-width: 500px;
        }

        @media (max-width: 600px) {
            max-width: 100%;
        }
    }

    form {
        margin-bottom: 80px;
    }

    #contact {
        margin-top: 20px;
    }

    #contact-message {
        width: 100%;
    }

    .btn-secondary {
        background-color: #001320;
        border: none;
        text-transform: none;

        @media (max-width: 600px) {
            padding: 15px 20px;
        }
    }

    #sol-comics-logo {
        width: 180px;

        @media (max-width: 600px) {
            max-width: 40%;
        }
    }
}

#footer {
    height: 80px;
    background-image: url(./assets/footer.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 14px;
    padding-right: 50px;
    padding-left: 50px;
    margin-top: 80px;

    @media (max-width: 500px) {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.modal-body {
    img {
        max-width: 100%;
    }

    #epimage {
        width: 90%;
        display: block;
        margin: auto;
        margin-bottom: 30px;
    }

    h1 {
        font-weight: bold;
        text-align: center;
        font-size: 40px;
        margin-bottom: 30px;
        color: #2a82b9;

        @media (max-width: 600px) {
            font-size: 30px;
        }

        @media (max-width: 600px) {
            font-size: 24px;
        }
    }
}

.members {
    max-width: 550px;
    margin: 0 auto;
}

#profile .membership-statuses {
    display: flex;
    align-items: flex-start;

    @media (max-width: 800px) {
        display: block;
    }
    
    .member {
        &:not(.active) {
            // width: calc(50% - 7px);

            // &:last-of-type {
            //     margin-right: 15px;
            // }

            @media (max-width: 800px) {
                width: auto;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .header {
            margin-bottom: 10px;
        }

        h2 {
            font-size: 20px;
        }
    }
}

#essential-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#job-progress {
    margin-bottom: 30px;
}

.member {
    margin: 0 auto 40px;

    .btn-secondary {
        padding: 5px 15px;
    }

    .btn-primary {
        padding: 10px 30px;
        display: block;
        margin: 0 auto;
    }

    .btn-primary, .btn-secondary {
        background-color: #e67c35;
        border: none;
    }

    .btn-primary.current {
        background-color: #fff;
        border-style: solid;
        border-color: #2a82b9;
        border-width: 0 2px;
        color: #2a82b9;
        padding: 5px 15px;
    }

    button {
        border-radius: 7px;
        border: none;
        text-transform: none;
        font-weight: 400;
        margin: 0;
    }
}

.member, #job-progress {
    padding: 20px;
    color: #495c68;
    border: 2px solid #a9e2f4;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;

    // width: calc(50% - 7px);

    // &:last-of-type {
    //     margin-right: 15px;
    // }

    @media (max-width: 800px) {
        width: auto;
        margin-bottom: 15px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    h2 {
        font-size: 26px;
        color: #2a82b9;
        padding-bottom: 0;
        margin-bottom: 0;

        @media (max-width: 600px) {
            font-size: 20px;
        }

        @media (max-width: 400px) {
            font-size: 18px;
        }
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: #495c68;
        margin-bottom: 20px;
    }

    .header {
        border-bottom: 1px solid #a9e2f4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        font-size: 18px;
        color: #2a82b9;

        span {
            margin-left: 20px;
        }
    }

    ul {
        padding: 15px;
        padding-bottom: 0;
        margin-top: 5px;
        margin-bottom: 20px;
        list-style-type: circle;
        border-bottom: 1px solid #a9e2f4;

        li {   
            margin-bottom: 20px;
            
            strong {
                color: #427a9e;
            }
        }
    }
}

#job-progress-wrap {
    overflow: visible;
}

#job-progress {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;

    @media (max-width: 800px) {
        margin-bottom: 15px;
    }

    @media (max-width: 650px) {
        margin-bottom: 0;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-weight: bold;
    }

    #progress-meter {
        max-width: 130px;
        margin-top: -25px;
        margin-right: 20px;
        
        @media (max-width: 650px) {
            max-width: 90px;
        }
    }

    .progress-text {
        @media (max-width: 650px) {
            h2 {
                font-size: 18px !important;
            }

            h3 {
                font-size: 16px !important;
            }
    
            p {
                font-size: 14px !important;
            }
        }

        @media (max-width: 500px) {
            margin-top: -20px;
        }

        h2 {
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            color: #495c68;
        }
    }
}

#UserProfile {
    #progress-meter {
        margin: -55px 20px -40px -10px;

        @media (max-width: 650px) {
            margin-top: -20px;
        }

        @media (max-width: 500px) {
            margin-left: -55px;
            margin-top: -30px;
        }
    }
}

#App #job-progress-outer {
    margin-bottom: 100px;
    position: relative;

    h2 {
        display: none;
    }

    h3 {
        font-size: 40px;
        color: #000;

        @media (max-width: $menu-max-width) {
            font-size: 30px !important;
        }
    }

    #job-progress {
        margin-bottom: 0;
    }

    #progress-meter {
        margin-right: 40px;
        margin-top: 0;

        @media (max-width: 500px) {
            margin-right: 20px;
        }
    }

    @media (max-width: $mobile-max-width) {
        padding-bottom: 70px;
    }

    .buttons {
        display: flex;
        max-width: $site-width;
        margin: 30px auto 0;

        @media (max-width: $mobile-max-width) {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        @media (min-width: $desktop-min-width) {
            justify-content: flex-start;
        }

        .btn-secondary {
            margin: 0;
            background: #c76828;
            color: #FFFFFF;
            border-color: #FFFFFF;

            @media (max-width: 500px) {
                white-space: nowrap;
                width: auto !important;
            }
        }
    }
}

#core-member {
    margin-right: 15px;

    @media (max-width: 800px) {
        margin-right: 0;
    }
}

.cart {
    margin: 0 20px 30px;
    text-align: left;

    .cart-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        @media (max-width: 700px) {
            display: block;
        }

        > * {
            padding: 10px 20px;
            border-right: 1px solid #ccc;

            @media (max-width: 700px) {
                border-right: none;
                border-bottom: 1px solid #ccc;
                padding: 20px 10px;
            }
        }
        
        .item-img {
            width: 150px;
            
            @media (max-width: 700px) {
                width: auto;
                text-align: center;

                img {
                    max-width: 200px;
                }
            }
        }

        .item-desc {
            flex-grow: 2;
        }

        .qty {
            width: 120px;

            @media (max-width: 700px) {
                width: auto;
            }
        }

        .total {
            border-right: none;
        }
    }
}

.paypal-buttons {
    margin: 0 auto;
    display: block !important;
}

.final_details {
    margin: 0 20px 30px;

    .detail {
        display: flex;
        justify-content: right;
        margin-bottom: 5px;

        .cost {
            min-width: 80px;
            text-align: right;
        }
    }
}

.order {
    .top {
        margin-bottom: 30px;
    }
}

#thank-you {
    text-align: center;
}

.paypal-buttons-label-subscribe {
    border-bottom: 1px solid #a9e2f4;
}

#App {
    #paypal_subscription_button_wrapper {
        margin-bottom: 50px;
    }
}

#CrewQuarters {
    h2 {
        padding-bottom: 15px;
    }
}

#categories {
    @media (min-width: 799px) {
        display: flex;
        justify-content: space-between;

        #classified {
            width: 64%;
        }

        #ttpoe {
            width: 34%;
        }
    }

    #classified {
        img {
            border: 2px solid #000;
        }
    }

    #ttpoe {
        h3:not(.header) {
            font-size: 22px;
        }
    }

    h3.header {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: rgb(47, 214, 220);
        padding: 10px 60px 30px 20px;
        margin: 0 0 -20px 0;
        border-radius: 10px;
        display: inline-block;
        position: relative;
        z-index: 0;
        background-color: #093252;
        background-image: url(./assets/white_slant.png);
        background-position: right -25px center;
        background-size: auto 125%;
        background-repeat: no-repeat;
    }
}

.readmore {
    font-size: 13px;
    text-decoration: none;
    border: 1px solid rgb(79, 114, 117);
    border-radius: 15px;
    padding: 3px 10px;
    color: #093252;
    float: right;
}

#section_list .post {
    @media(min-width: 800px) {
        display: flex;

        .post_image {
            margin-right: 20px;
            
            img {
                border: 1px solid #aaa;
            }
        }

        .post_content {
            margin-top: -5px;
        }
    }
}

.post_list_inner {
    box-shadow: 1px -13px 25px -18px rgba(0,0,0,0.5);
    -webkit-box-shadow: 1px -13px 25px -18px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px -13px 25px -18px rgba(0,0,0,0.5);
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    z-index: 1;

    .post {
        margin-bottom: 40px;
        border-bottom: 1px solid #a6d5f4;
        padding-bottom: 20px;

        img {
            max-width: 100%;
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 20px;
            font-size: 28px;

            a {
                text-decoration: none;
                color: #001320;

                // &:hover {
                //     text-decoration: underline;
                // }
            }
        }

        // img {
        //     max-width: 400px;
        //     width: 100%;
        // }

        .bottom {
            clear: both;
        }

        // &#first_post {
        //     img {
        //         float: left;
        //         margin: 0 30px 20px 0;
        //         border: none;
        //         padding-left: 0;
        //     }
        // }

        // &.post {
        //     h3 {
        //         text-align: left;
        //     }

        //     img {
        //         float: right;
        //         margin: 0 0 20px 15px;
        //         padding-left: 15px;
        //         border-left: 1px solid #001320;
        //     }
        // }
    }
}

#Post {
    .section-body {
        background: #FFF;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 20px;

        @media (max-width: $menu-max-width) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 40px;
        }

        @media (max-width: $mobile-max-width) {
            padding-top: 20px;
        }
    }

    .section-1 {
        margin-bottom: 30px;
    }

    .image-gallery {
        margin-bottom: 30px;
    }

    img {
        width: 100%;
        margin-bottom: 20px;
    }

    .type1 {
        img {
            &:first-of-type {
                float: left;
                max-width: 400px;
                margin: 0 20px 20px 0;
            }

            &:nth-last-of-type(3) {
                float: right;
                max-width: 400px;
                margin: 0 0 20px 20px;
            }
        }
    }
    
    .type2 {
        img {
            &:first-of-type {
                float: right;
                max-width: 400px;
                margin: 0 0 20px 20px;
            }

            &:nth-last-of-type(3) {
                float: left;
                max-width: 400px;
                margin: 0 20px 20px 0;
            }
        }
    }

    .image-gallery-image {
        max-height: 400px;
    }

    .fullscreen {
        .image-gallery-image {
            max-height: 80vh;
        }

        .image-gallery-thumbnail-image {
            max-height: 17vh;
        }
    }

    @media(max-width: 700px) {
        img {
            max-width: 100% !important;
        }
    }
}

#shop {
    z-index: 50;
    position: relative;

    a:hover {
        color: #000;

        &.sprd-button {
            color: #fff;
        }
    }
}